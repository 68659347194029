/**
 * @abstract
 * */
class Icon {
  /**
   * Icon's Constructor.
   *
   * @param {string} name
   * @param {function|string|null} matcher
   * */
  constructor(name, matcher = null) {
    this.name = name;
    this.matcher = matcher;
  }

  /**
   * Verifica se método fornecido utiliza o ícone.
   *
   * @return {boolean}
   * */
  match(methodName) {
    if (typeof this.matcher === "function") {
      return this.matcher(methodName);
    }
    let text = this.name;
    if (typeof this.matcher === "string") {
      text = this.matcher;
    }
    return methodName.indexOf(text) > -1;
  }

  /**
   * Retorna as definições do ícone.
   *
   * @abstract
   * @return {object}
   * */
  getDef() {
    // eslint-disable-next-line no-console
    console.error("Method getDef() must be implemented.");
  }
}

export class SvgIcon extends Icon {
  /**
   * {@inheritDoc}
   * */
  getDef() {
    return {
      type: "svg",
      name: this.name,
    };
  }
}

export class FontAwesomeIcon extends Icon {
  /**
   * {@inheritDoc}
   * */
  constructor(prefix, name, matcher) {
    super(name, matcher);
    this.prefix = prefix;
  }
  /**
   * {@inheritDoc}
   * */
  getDef() {
    return {
      type: "font",
      prefix: this.prefix,
      name: this.name,
    };
  }
}
