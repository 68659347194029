import { mapGetters } from "vuex";
import parsePhoneNumber from "libphonenumber-js";

export default {
  data() {
    return {
      REGEX_PHONE: /(\d{2})(\d{4})(\d{4})/,
      REGEX_MOBILE_PHONE: /(\d{2})(\d{5})(\d{4})/,
      MIN_LENGTH_PHONE: 5,
    };
  },

  computed: {
    ...mapGetters(["storeSettings"]),

    /**
     * Valida se a loja tem opção de envio nacional
     * @return {bool}
     */
    isNationalShipping() {
      return this.storeSettings.international_shipping == 0;
    },

    /**
     * Deve aplicar a mascara de telefone nacional se:
     *
     * 1 - A loja tem opção de envio nacional
     *
     * @return {bool}
     */
    isNationalNumber() {
      return (
        this.isNationalShipping
      );
    },
  },

  methods: {
    /**
     * Aplica a mascara no telefone
     * Principal função do mixin
     * @param {string} phoneNumberUnformatted
     * @return {string}
     */
    phoneMask(phoneNumberUnformatted) {
      const onlyNumbers = phoneNumberUnformatted.replace(/\D/g, "");

      if (this.isNationalNumber) {
        return this.nationalNumber(onlyNumbers);

      }
      return this.internationalNumber(onlyNumbers);
    },

    /**
     * Retorna o número internacional formatado
     * @param {string} onlyNumbers
     * @return {string}
     */
    internationalNumber(onlyNumbers) {
      if (onlyNumbers.length < this.MIN_LENGTH_PHONE) {
        return `+${onlyNumbers}`;
      }

      const phoneNumber = parsePhoneNumber(`+${onlyNumbers}`);
      return phoneNumber.formatInternational();
    },

    /**
     * Retorna o número nacional formatado
     * @param {string} onlyNumbers
     * @return {string}
     */
    nationalNumber(onlyNumbers) {
      if (onlyNumbers.length > 10) {
        onlyNumbers = this.removeDDI(onlyNumbers);
      }

      const numberWithMask = onlyNumbers.replace(
        this.regexPhoneType(onlyNumbers),
        "($1) $2-$3"
      );

      return numberWithMask;
    },

    /**
     * Retorna o tipo de regex de acordo com o tamanho do número
     * @param {string} numberWithoutDDI
     * @return {string}
     */
    regexPhoneType(numberWithoutDDI) {
      return numberWithoutDDI.length > 10
        ? this.REGEX_MOBILE_PHONE
        : this.REGEX_PHONE;
    },

    /**
     * Remove o DDI do número
     * @param {string} number
     * @return {string}
     */
    removeDDI(number) {
      if (number[0] == 5 && number[1] == 5) {
        number = number.substring(2);
      }

      return number;
    },
  },
};
