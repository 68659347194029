import isValidCnpj from "@brazilian-utils/is-valid-cnpj";

/**
 * Verifi ca se o CNPJ tem formato e numeração válidos
 * @param {bool} required Se é obrigatório ou não
 * @param {string} cnpj Cnpj do cliente
 * @return {bool}
 */
export const isCnpjFormatValid = (required) => (cnpj) => {
  if (cnpj.length === 0 && !required) {
    return true;
  }

  if (!cnpj) {
    return false;
  }

  return isValidCnpj(cnpj);
};

export default isCnpjFormatValid;
