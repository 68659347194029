<script>
import { mapState, mapActions, mapGetters } from "vuex";
import AppAlert from "@/features/bootstrap/Alert";
import AppProgress from "@/features/general/Progress";
import AppIcon from "@/features/general/Icons";
import AppProfileInfo from "./profile-info";
import AppPersonalEdit from "./edit/personal";
import AppBusinessEdit from "./edit/business";
import AppAdditionalEdit from "./edit/additional";

export default {
  name: "AppCustomer",
  components: {
    AppProfileInfo,
    AppPersonalEdit,
    AppBusinessEdit,
    AppAdditionalEdit,
    AppProgress,
    AppAlert,
    AppIcon,
  },

  data() {
    return {
      isSaving: false,
      CUSTOMER_TYPE_NUMBER: {
        NATURAL: "1",
        LEGAL: "2",
        BOTH: "0",
      },
      CUSTOMER_TYPE_STRING: {
        NATURAL: "pf",
        LEGAL: "pj",
        BOTH: "both",
      },
    };
  },

  beforeMount() {
    this.clearMessages();
  },

  created() {
    this.setPreviousCpf();
  },

  methods: {
    ...mapActions("Customer", [
      "setData",
      "setMessage",
      "clearMessages",
      "setBackendErrors",
      "clearBackendErrors",
      "setCustomerAttribute",
    ]),

    /**
     * Verifica se a loja está configurada para aceitar cadastros de pessoa física
     * @return {bool}
     */
    isNaturalPersonEnabled() {
      return (
        this.storeSettings.enable_customer_edit ===
          this.CUSTOMER_TYPE_NUMBER.NATURAL ||
        this.storeSettings.enable_customer_edit ===
          this.CUSTOMER_TYPE_NUMBER.BOTH
      );
    },

    /**
     * Verifica se a loja está configurada para aceitar cadastros de pessoa jurídica
     * @return {bool}
     */
    isLegalPersonEnabled() {
      return (
        this.storeSettings.enable_customer_edit ===
          this.CUSTOMER_TYPE_NUMBER.LEGAL ||
        this.storeSettings.enable_customer_edit ===
          this.CUSTOMER_TYPE_NUMBER.BOTH
      );
    },

    /**
     * Verifica se deve exibir os inputs de PJ.
     * Caso seja definido que só aceitará cadastros de PJ, deverá exibir os inputs de PJ
     * automaticamente, sem necessitar de ação de botões.
     * @return {bool}
     */
    showLegalInputs() {
      const legalSelected = this.type === this.CUSTOMER_TYPE_STRING.LEGAL;
      return (
        this.isLegalPersonEnabled() &&
        (legalSelected || !this.isNaturalPersonEnabled())
      );
    },

    /**
     * Verifica se deve mostrar os botões de alternância entre formulários de PF e PJ.
     * @return {bool}
     */
    showTabButtons() {
      return this.isLegalPersonEnabled() && this.isNaturalPersonEnabled();
    },

    /**
     * Converte o tipo do formato string(pf, pj, both) para o tipo numérico(1, 2, 0)
     */
    typeStringToAttributeTypeString(type) {
      if (type === this.CUSTOMER_TYPE_STRING.NATURAL) {
        return "natural";
      }

      if (type === this.CUSTOMER_TYPE_STRING.LEGAL) {
        return "legal";
      }

      return "both";
    },

    /**
     * Envia os dados do formulário
     * @return {bool|Promise}
     */
    async onSubmit() {
      const isPersonalFormCorrect =
        await this.$refs.personalForm.v$.$validate();
      if (!isPersonalFormCorrect) return;

      if (this.type === "pj") {
        const isBusinessFormCorrect =
          await this.$refs.businessForm.v$.$validate();
        if (!isBusinessFormCorrect) return;
      }

      this.clearMessages();
      this.$bus.emit("submit-data");
      if (this.isSaving) {
        return false;
      }

      this.isSaving = true;

      const customerPath = `customer/${this.list.hash}`;
      let formData = {
        email: this.list.email,
        id: this.list.hash,
        phone: this.list.phone.replace(/\D/g, ""),
        mobile_phone: this.list.mobile_phone.replace(/\D/g, ""),
        name: this.list.name,
        rg: this.list.rg,
        gender: this.list.gender,
        type: this.list.type,
        cpf: this.list.cpf,
        birth_date: this.list.birth_date,
        newsletter: this.list.newsletter,
        is_foreign: this.list.is_foreign,
      };

      if (this.showLegalInputs()) {
        const legalPersonData = {
          company_name: this.list.company.name,
          cnpj: this.list.company.cnpj,
          state_inscription: this.list.company.state_inscription,
        };

        formData = { ...formData, ...legalPersonData };
      }

      const hasCustomerAttributes =
        Object.keys(this.list.customer_attributes).length > 0;
      if (hasCustomerAttributes) {
        const extensions = this.parseCustomerAttributes();
        formData = { ...formData, ...{ extensions } };
      }

      this.setPreviousCpf();

      return this.$http
        .put(customerPath, formData)
        .then((response) => {
          this.isSaving = false;
          this.clearBackendErrors();

          this.setMessage({
            variant: "success",
            text: `${this.langs.profile.success_message}`,
          });
          this.scrolTop();
          this.setCustomerAttribute(response.data.message.customerAttributes);
        })
        .catch((errors) => {
          this.isSaving = false;
          const backErrors = {};
          Object.assign(backErrors, errors);
          sessionStorage.removeItem("hasPreviousCpf");

          if (backErrors.data.errors["extensions.attributes"] !== undefined) {
            const errorsObject = JSON.parse(
              errors.data.errors["extensions.attributes"][0]
            );
            backErrors.data.errors["extensions.attributes"][0] = errorsObject;
          }

          const backendErrors = backErrors.data.errors;
          this.setBackendErrors({ backendErrors });
        });
    },

    /**
     * Reúne e formata os atributos customizados compatíveis com o tipo de pessoa atual
     * para prepará-los para o envio
     * @return {array} Array de objetos com os atributos
     */
    parseCustomerAttributes() {
      const type = this.typeStringToAttributeTypeString(this.list.type);
      const extensions = { attributes: [] };

      Object.entries(this.list.customer_attributes).forEach((entry) => {
        const values = entry[1];

        if (
          values.person_type === type ||
          values.person_type === this.CUSTOMER_TYPE_STRING.BOTH
        ) {
          let formattedAttr = {
            customer_attribute_id: values.customer_attribute_id,
            value: values.value,
          };

          if (values.customer_relation_id !== "") {
            formattedAttr = {
              ...formattedAttr,
              ...{ id: values.customer_relation_id },
            };
          }
          extensions.attributes.push(formattedAttr);
        }
      });
      return extensions;
    },

    /**
     * Recupera o CPF previamente inserido
     * @return {undefined}
     */
    setPreviousCpf() {
      if (this.list.cpf.length <= 0) {
        return;
      }

      sessionStorage.setItem("hasPreviousCpf", this.list.cpf);
    },

    /**
     * Posiciona o scroll da pagina no topo
     * @return void
     */
    scrolTop() {
      window.scroll({
        top: 130,
        left: 0,
        behavior: "smooth",
      });
    },

    /**
     * Seleciona a visualização de inputs de PF
     * @return {void}
     */
    selectNaturalEdit() {
      this.type = this.CUSTOMER_TYPE_STRING.NATURAL;
    },

    /**
     * Seleciona a visualização de inputs de PJ
     * @return {void}
     */
    selectLegalEdit() {
      this.type = this.CUSTOMER_TYPE_STRING.LEGAL;
    },

    /**
     * Verifica o estado inválido dos formulários para o controle do estado do botão de envio
     */
    $isInvalid() {
      const isOnlyNaturalAllowed =
        this.isNaturalPersonEnabled() && !this.isLegalPersonEnabled();
      const isOnlyLegalAllowed =
        !this.isNaturalPersonEnabled() && this.isLegalPersonEnabled();

      const isNaturalInvalid = this.v$PersonalInvalid;
      const isAnyInvalid = this.v$PersonalInvalid || this.v$BusinessInvalid;

      const isNaturalSelected = this.type === this.CUSTOMER_TYPE_STRING.NATURAL;
      const isLegalSelected = this.type === this.CUSTOMER_TYPE_STRING.LEGAL;

      const isNaturalAttrInvalid = this.v$PersonalAttrInvalid;
      const isLegalAttrInvalid = this.v$BusinessAttrInvalid;
      const isBothAttrInvalid = this.v$BothAttrInvalid;

      if (
        ((isOnlyLegalAllowed || isLegalSelected) && isAnyInvalid) ||
        ((isOnlyNaturalAllowed || isNaturalSelected) && isNaturalInvalid) ||
        (isNaturalSelected && isNaturalAttrInvalid) ||
        (isLegalSelected && isLegalAttrInvalid) ||
        isBothAttrInvalid
      ) {
        return true;
      }

      return false;
    },
  },

  computed: {
    ...mapState("Customer", {
      list: (state) => state.customer.list,
      messages: (state) => state.customer.messages,
      v$PersonalInvalid: (state) => state.customer.isPersonalDataInvalid.value,
      v$BusinessInvalid: (state) => state.customer.isBusinessDataInvalid.value,
      v$PersonalAttrInvalid: (state) =>
        state.customer.isPersonalAttributeDataInvalid,
      v$BusinessAttrInvalid: (state) =>
        state.customer.isBusinessAttributeDataInvalid,
      v$BothAttrInvalid: (state) => state.customer.isBothAttributeDataInvalid,
    }),

    ...mapGetters("Customer", ["customerProgress"]),
    ...mapGetters(["storeSettings", "mainLoading", "storeInfo", "langs"]),

    checkProgress() {
      return this.mainLoading ? 1 : this.customerProgress;
    },

    checkLabel() {
      return this.customerProgress < 100
        ? `${this.langs.profile["complete_personal_info"]}`
        : `${this.langs.profile["registration_complete"]}`;
    },

    checkTitle() {
      return this.customerProgress < 100
        ? `${this.langs.profile["almost_there"]}`
        : `${this.langs.profile["congrats"]}`;
    },

    type: {
      get() {
        return this.list.type;
      },
      set(type) {
        this.setData({ list: { type } });
      },
    },

    /**
     * Verifica se a loja possui características ativas de clientes
     * @return {bool}
     */
    hasActiveCustomerAttributes() {
      return Object.keys(this.list.customer_attributes).length > 0;
    },

    /**
     * Verifica a label a ser usada ao salvar
     * @return {string}
     */
    labelForSaving() {
      if (this.isSaving) {
        return this.langs.profile.saving;
      }

      return this.langs.profile.save_changes;
    },
  },
};
</script>
<template>
  <section>
    <header>
      <h3 class="__title--3" :class="{ app__loading: mainLoading }">
        {{ this.langs.sidebar_menu["my-information"] }}
      </h3>
    </header>
    <section class="app__customer">
      <app-profile-info
        :profile="this.list.profile"
        :loading="mainLoading"
      ></app-profile-info>
      <header class="app__customer__header">
        <app-alert :messages="messages" />
      </header>
      <div class="row">
        <div class="col-sm-12 col-md-10">
          <div class="app__data-edit__tab" v-if="showTabButtons()">
            <button
              class="app__data-edit__tab__button"
              data-test="data-edit__tab__button--natural"
              :class="{
                'app__data-edit__tab__button--selected':
                  this.type === this.CUSTOMER_TYPE_STRING.NATURAL,
              }"
              @click="selectNaturalEdit()"
            >
              <figure class="app__data-edit__tab__icon">
                <app-icon prefix="fa" name="user"></app-icon>
              </figure>
              <label class="app__data-edit__tab__label">
                {{ this.langs.profile["natural-person"] }}
              </label>
            </button>

            <button
              class="app__data-edit__tab__button"
              data-test="data-edit__tab__button--legal"
              :class="{
                'app__data-edit__tab__button--selected':
                  this.type === this.CUSTOMER_TYPE_STRING.LEGAL,
              }"
              @click="selectLegalEdit()"
            >
              <figure class="app__data-edit__tab__icon">
                <app-icon prefix="fa" name="user-tie"></app-icon>
              </figure>
              <label class="app__data-edit__tab__label">
                {{ this.langs.profile["legal-person"] }}
              </label>
            </button>
          </div>
          <form
            class="app__customer-personal-edit"
            action="POST"
            @submit.prevent="onSubmit"
          >
            <app-business-edit
              ref="businessForm"
              :customer="this.list"
              :loading="mainLoading"
              v-if="showLegalInputs()"
            >
            </app-business-edit>

            <app-personal-edit
              ref="personalForm"
              :customer="this.list"
              :loading="mainLoading"
            >
            </app-personal-edit>

            <app-additional-edit
              :loading="mainLoading"
              v-if="this.hasActiveCustomerAttributes"
            />

            <div class="row">
              <div class="col-12 app__customer__actions">
                <button
                  class="app__button app__button--primary app__button--no-margin"
                  :class="{
                    app__loading: mainLoading,
                    'app__button--disabled': isSaving,
                    app__isSaving: isSaving,
                  }"
                  type="submit"
                  :disabled="isSaving"
                >
                  {{ this.labelForSaving }}
                  <app-icon
                    class="app__input-group__icon-sync-alt app__icon--infinite-rotate"
                    prefix="fa"
                    name="sync-alt"
                    v-show="isSaving"
                  >
                  </app-icon>
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="d-none d-md-block col-md-2 app__customer__progress">
          <div class="row">
            <div class="col-md-12">
              <app-progress
                :loading="mainLoading"
                :width="75"
                :progress="checkProgress"
                :title="checkTitle"
                :label="checkLabel"
              >
              </app-progress>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>
