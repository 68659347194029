import isValidCpf from "@brazilian-utils/is-valid-cpf";
import moment from "moment";
import libphonenumber from "google-libphonenumber";
import parsePhoneNumber from "libphonenumber-js";

/**
 * Verifica se o valor é vazio e não-obrigatório
 * @param {mixed} value
 * @param {bool} required
 * @return {bool}
 */
const isEmptyAndOptional = (value, required) => value.length === 0 && !required;

/**
 * Verifica se a string contem dois nomes separados por um " "
 * @param {string} name
 * @return {boolean}
 */
export const hasLastName = (name) => /\s/g.test(name.trim());

/**
 * Verifica se o CPF tem formato e numeração válidos
 * @param {string} cpf Cpf do cliente
 * @return {bool}
 */
export const isCpfFormatValid = (required) => (cpf) => {
  if (isEmptyAndOptional(cpf, required)) {
    return true;
  }

  if (!cpf && required) {
    return false;
  }

  return isValidCpf(cpf);
};

/**
 * Verifica se o cliente informou uma data de nascimento válida
 * @param {string} birthDate Data de nascimento dd/mm/yyyy
 * @return {bool}
 */
export const isBirthDate = (required) => (birthDate) => {
  if (isEmptyAndOptional(birthDate, required)) {
    return true;
  }

  const DATE_EXACT_VALID_LENGTH = 8;

  const birthDateOnlyNumbers = birthDate.replace(/[^0-9]/g, "");
  if (birthDateOnlyNumbers.length !== DATE_EXACT_VALID_LENGTH) {
    return false;
  }

  const birthDateObject = moment(birthDate, "DD/MM/YYYY");
  const now = moment();
  const isDateBiggerThanNow = birthDateObject.valueOf() > now.valueOf();

  return !isDateBiggerThanNow && birthDateObject.isValid();
};

/**
 * Verifica se a idade do cliente é menor que a máxima permitida.
 * @param {int} max Idade máxima para cadastro de acordo com configuração da loja
 * @return {bool}
 */
export const isValidByMaxAge = (max, required) => (birthDate) => {
  if (isEmptyAndOptional(birthDate, required)) {
    return true;
  }

  const DEFAULT_MAX_AGE = 122;
  const birthDateObject = moment(birthDate, "DD/MM/YYYY");
  const maxAge = parseInt(max, 10) || DEFAULT_MAX_AGE;
  const age = moment().diff(birthDateObject, "years");

  return age < maxAge;
};

/**
 * Verifica se a idade do cliente é maior que a mínima requisitada
 * @param {int} max Idade mínima para cadastro de acordo com configuração da loja
 * @return {bool}
 */
export const isValidByMinAge = (min, required) => (birthDate) => {
  if (isEmptyAndOptional(birthDate, required)) {
    return true;
  }

  const DEFAULT_MIN_AGE = 0;
  const birthDateObject = moment(birthDate, "DD/MM/YYYY");
  const minAge = parseInt(min, 10) || DEFAULT_MIN_AGE;
  const age = moment().diff(birthDateObject, "years");

  return age > minAge;
};

/**
 * Valida os números de telefone e celular
 * @param {int} requiredType Tipo do número para validação. 0 = FIXED LINE | 1 = MOBILE
 * @return {bool}
 */
export const isValidPhoneNumber = (requiredType) => (phoneNumber) => {
  const phoneNumberOnlyNumbers = phoneNumber.replace(/[^0-9]/g, "");

  const MIN_LENGTH_PHONE = 9;
  if (phoneNumberOnlyNumbers.length < MIN_LENGTH_PHONE) {
    return false;
  }

  const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
  const parsedNumber = phoneUtil.parseAndKeepRawInput(
    "55" + phoneNumberOnlyNumbers,
    "BR"
  );

  const isValidNumber = phoneUtil.isValidNumber(parsedNumber);
  if (!isValidNumber) {
    return false;
  }

  const numberType = phoneUtil.getNumberType(parsedNumber);
  if (numberType !== requiredType) {
    return false;
  }

  return true;
};

/**
 * Valida o tamanho do número estrangeiro
 * @param {string} phone_number número de telefone que deseja validar
 * @return {bool}
 */
export const isValidForeignPhoneNumber = (requiredType) => (phoneNumber) => {
  const onlyNumbers = phoneNumber.replace(/[^0-9]/g, "");
  const parsedPhoneNumber = parsePhoneNumber(`+${onlyNumbers}`);
  if (!parsedPhoneNumber) {
    return false;
  }

  const IS_BR = parsedPhoneNumber.country === "BR";

  const IS_MOBILE = 1;
  const MIN_LENGTH_PHONE = requiredType == IS_MOBILE ? 14 : 13;
  const HAS_MIN_LENGTH = parsedPhoneNumber.number.length < MIN_LENGTH_PHONE;

  if (IS_BR && HAS_MIN_LENGTH) {
    return false;
  }

  const isValidNumber = parsedPhoneNumber.isValid();
  if (!isValidNumber) {
    return false;
  }

  return true;
};

/**
 * Valida o comprimento da string, considerando somente números.
 * @param {int} minLen Mínimo comprimento requerido
 * @param {int} maxLen Máximo comprimento permitido
 * @return {bool}
 */
export const isValidLength = (minLen, maxLen, required) => (value) => {
  if (isEmptyAndOptional(value, required)) {
    return true;
  }

  if (!value) {
    return false;
  }

  const valueOnlyNumbers = value.replace(/[^0-9A-Za-z]/g, "");
  return valueOnlyNumbers.length >= minLen && valueOnlyNumbers.length <= maxLen;
};
