<script>
import { mapActions, mapGetters, mapState } from "vuex";
import AppInputGroup from "@/features/general/InputGroup";
export default {
  name: "AppCustomerAdditionalEdit",
  components: {
    AppInputGroup,
  },
  props: {
    loading: Boolean,
  },

  data() {
    return {
      ATTRIBUTE_TYPE: {
        NATURAL: "natural",
        LEGAL: "legal",
        BOTH: "both",
      },
      touched: {},
      attrErrors: {
        natural: [],
        legal: [],
        both: [],
      },
    };
  },

  mounted() {
    this.$nextTick(() => {
      Object.entries(this.customer_attributes).forEach((attribute) => {
        const attr = attribute[1];
        this.setHasAnyError(attr);
      });
    });
  },

  methods: {
    ...mapActions("Customer", [
      "setData",
      "setCustomerAttribute",
      "setIsPersonalAttributeDataInvalid",
      "setIsBusinessAttributeDataInvalid",
      "setIsBothAttributeDataInvalid",
    ]),

    /**
     * Retorna o state de acordo com as opções do validador
     * @param {int} id Id do atributo
     * @returns {bool}
     */
    getValidatorState(attr) {
      const attrId = attr.customer_attribute_id;
      const hasValue = this.customer_attributes[attrId].value.length > 0;
      const wasTouched = this.touched[attrId] !== undefined;
      const isRequired =
        this.customer_attributes[attrId].required.$response === "1";

      const isRequiredEmpty = !hasValue && wasTouched && isRequired;
      if (this.isAttributeWithBackendError(attr) || isRequiredEmpty) {
        return "invalid";
      }

      if (hasValue) {
        return "valid";
      }

      return "initial";
    },

    /**
     * Marca quando os campos de atributos possuem erros, considerando o
     * tipo de pessoa respectivo
     * @param {bool} hasError Valor desejado para a flag
     * @param {object} attr Objeto do atributo
     * @return {void}
     */
    setHasAnyError(attr) {
      const attrType = attr.person_type;
      const attrId = attr.customer_attribute_id;
      const alreadyInErrorList =
        this.attrErrors[attrType].indexOf(attrId) !== -1;
      const hasError = this.isInvalid(attr);

      const isInitialRequiredEmpty =
        !hasError && attr.value.length === 0 && attr.required.$response === "1";

      if (!hasError && !alreadyInErrorList && isInitialRequiredEmpty) {
        this.attrErrors[attrType].push(attrId);
      }

      if (hasError && !alreadyInErrorList) {
        this.attrErrors[attrType].push(attrId);
      }

      if (!hasError && alreadyInErrorList) {
        const i = this.attrErrors[attrType].findIndex(
          (value) => value === attrId
        );
        this.attrErrors[attrType].splice(i, 1);
      }

      const hasAnyNaturalInvalid = this.attrErrors.natural.length > 0;
      const hasAnyLegalInvalid = this.attrErrors.legal.length > 0;
      const hasAnyBothInvalid = this.attrErrors.both.length > 0;

      this.setIsPersonalAttributeDataInvalid(hasAnyNaturalInvalid);
      this.setIsBusinessAttributeDataInvalid(hasAnyLegalInvalid);
      this.setIsBothAttributeDataInvalid(hasAnyBothInvalid);
    },

    /**
     * Sinaliza quando houve interação do usuário com um atributo
     * @param {object} attr Objeto do atributo
     * @return {void}
     */
    touch(attr) {
      this.touched[attr.customer_attribute_id] = true;
    },

    /**
     * Retorna os valores de options. Usado para atributos que são <select>
     * @param {object} attribute Objetoedit/additional.vue completo do atributo
     * @return {array} Array de objetos com as opções
     */
    getAttributeSelectValues(attribute) {
      const items = [
        { value: "", text: "Selecione uma opção", disabled: true },
      ];

      attribute.CustomerAttributeOption.forEach((option) => {
        items.push({ value: option.name, text: option.name });
      });
      return items;
    },

    /**
     * Verifica se o atributo é do mesmo tipo do tipo
     * de pessoa(PF, PJ) selecionado atualmente
     * @param {object} attribute Objeto completo do atributo
     * @return {boolean}
     */
    isCompatibleType(attribute) {
      const currentType =
        this.type === "pf"
          ? this.ATTRIBUTE_TYPE.NATURAL
          : this.ATTRIBUTE_TYPE.LEGAL;
      const isCompatible =
        attribute.person_type === this.ATTRIBUTE_TYPE.BOTH ||
        attribute.person_type === currentType;

      return isCompatible;
    },

    /**
     * Seta o atributo dinâmico no state de customer
     * @param {string} inputValue Valor do atributo
     * @param {object} attribute Objeto do atributo
     * @param {int} index Índice do objeto na lista de atributos
     * @return {void}
     */
    setAttribute(inputValue, attribute, index) {
      const newAttr = { ...attribute, value: inputValue };

      this.setCustomerAttribute({ [index]: newAttr });
    },

    /**
     * Verifica se o atributo customizado possui algum erro a partir das validações
     * do backend
     * @param {int} attrId Id do atributo
     * @return {bool}
     */
    isAttributeWithBackendError(attr) {
      const attrId = attr.customer_attribute_id;
      const attrName = `attribute${attrId}`;
      const haveAnyError =
        this.backendErrors["extensions.attributes"] !== undefined &&
        this.backendErrors["extensions.attributes"].length > 0;

      if (haveAnyError) {
        const error = this.backendErrors["extensions.attributes"][0];
        return error[attrName] !== undefined;
      }

      return false;
    },

    /**
     * Verifica se o atributo é inválido
     * @param {object} attr Objeto do atributo
     * @return {bool}
     */
    isInvalid(attr) {
      return this.getValidatorState(attr) === "invalid";
    },

    /**
     * Verifica se o atributo é opcional para contruir a label/placeholder
     * @param {object} attr Objeto do atributo
     * @return {string}
     */
    getLabel(attr) {
      return attr.required.$response === "1"
        ? attr.description
        : `${attr.description} (Opcional)`;
    },
  },

  computed: {
    ...mapGetters(["langs"], ["storeSettings"]),
    ...mapState("Customer", {
      customer: (state) => state.customer.list,
      backendErrors: (state) => state.customer.backendErrors,
    }),
    ...mapState({ store: (state) => state.store_info.list }),

    type: {
      get() {
        return this.customer.type;
      },
      set(type) {
        this.setData({ list: { type } });
      },
    },

    /* eslint-disable camelcase */
    customer_attributes: {
      get() {
        return this.customer.customer_attributes;
      },
      set(customer_attributes) {
        this.setData({ list: { customer_attributes } });
      },
    },

    /**
     * Verifica se deve ou não mostrar o conteúdo do componente dependendo dos atributos ativos
     * e do tipo atual de pessoa
     * @return {bool}
     * @see https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Global_Objects/Object/entries
     */
    showContent() {
      const attrs = Object.entries(this.customer_attributes);

      if (attrs.length === 0) {
        return false;
      }

      const natural = attrs.filter(
        (attr) => attr[1].person_type === this.ATTRIBUTE_TYPE.NATURAL
      ).length;
      const legal = attrs.filter(
        (attr) => attr[1].person_type === this.ATTRIBUTE_TYPE.LEGAL
      ).length;
      const both = attrs.filter(
        (attr) => attr[1].person_type === this.ATTRIBUTE_TYPE.BOTH
      ).length;

      if (this.type === "pf" && (natural || both)) {
        return true;
      }

      if (this.type === "pj" && (legal || both)) {
        return true;
      }

      return false;
    },
  },
};
</script>
<template>
  <section class="app__customer-additional-edit" v-show="this.showContent">
    <h3 class="app__customer__title app__customer__title--3">
      {{ this.langs.profile["more_information"] }}
    </h3>
    <div class="row">
      <template v-for="(attr, index) in customer_attributes">
        <div class="col-12 col-md-6" :key="index" v-if="isCompatibleType(attr)">
          <app-input-group
            v-if="attr.type === 'text'"
            :label="getLabel(attr)"
            :identifier="`attribute${attr.customer_attribute_id}`"
            :icon="{ prefix: 'far', name: 'file' }"
            :loading="loading"
            :value="attr.value"
            :state="getValidatorState(attr)"
            @focus="touch(attr)"
          >
            <template v-slot:input>
              <input
                type="text"
                :placeholder="getLabel(attr)"
                :id="`attribute${attr.customer_attribute_id}`"
                @keyup="setHasAnyError(attr)"
                @focus="touch(attr)"
                class="form-control app__input-group__input app__input-group__input--has-float-label"
                :class="{
                  app__loading: loading,
                  'app__input-group__input--is-invalid': isInvalid(attr),
                  'app__input-group__input--is-empty': attr.value.length === 0,
                }"
                :value="attr.value"
                @input="setAttribute($event.target.value, attr, index)"
              />
            </template>
            <template slot:feedbacks>
              <small
                class="app__input-group__feedbacks app__input-group__feedbacks--backend"
              >
                <span
                  v-if="isInvalid(attr)"
                  class="app__input-group__feedbacks__feedback"
                >
                  {{ this.langs.profile["required_field"] }}
                </span>

                <span
                  v-else-if="isAttributeWithBackendError(attr)"
                  class="app__input-group__feedbacks__feedback"
                >
                  {{
                    backendErrors["extensions.attributes"][0][
                      `attribute${attr.customer_attribute_id}`
                    ]
                  }}
                </span>
              </small>
            </template>
          </app-input-group>

          <app-input-group
            v-else-if="attr.type == 'select'"
            :label="getLabel(attr)"
            :identifier="`attribute${attr.customer_attribute_id}`"
            :icon="{ prefix: 'far', name: 'file' }"
            :loading="loading"
            :value="attr.value"
            :state="getValidatorState(attr)"
            @change="setHasAnyError(attr)"
            @focus="touch(attr)"
            :key="index"
          >
            <template v-slot:input>
              <select
                :placeholder="getLabel(attr)"
                :id="`attribute${attr.customer_attribute_id}`"
                class="app__input-group__select form-control"
                :class="{
                  app__loading: loading,
                  'app__input-group__select--is-empty': attr.value === 0,
                  'app__input-group__select--has-float-label': getLabel(attr),
                }"
                :value="attr.value"
                @input="setAttribute($event.target.value, attr, index)"
                @focus="touch(attr)"
              >
                <option
                  v-for="(option, index) in getAttributeSelectValues(attr)"
                  :key="index"
                  :selected="index === 0 ? 'selected' : false"
                  :value="option.value"
                >
                  {{ option.text }}
                </option>
              </select>
            </template>
            <template slot:feedbacks>
              <small
                class="app__input-group__feedbacks app__input-group__feedbacks--backend"
              >
                <span
                  v-if="isInvalid(attr)"
                  class="app__input-group__feedbacks__feedback"
                >
                  {{ this.langs.profile["required-field"] }}
                </span>

                <span
                  v-else-if="isAttributeWithBackendError(attr)"
                  class="app__input-group__feedbacks__feedback"
                >
                  {{
                    backendErrors["extensions.attributes"][0][
                      `attribute${attr.customer_attribute_id}`
                    ]
                  }}
                </span>
              </small>
            </template>
          </app-input-group>
        </div>
      </template>
    </div>
  </section>
</template>
