export default {
  data() {
    return {
      data: null,
    };
  },
  methods: {
    /**
     * Valida o ícone e o retorna
     */
    hasDef(icons) {
      // eslint-disable-next-line no-return-assign
      icons.some((icon) => !!(this.data = icon.getDef()));

      return this.data;
    },
  },
};
