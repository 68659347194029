<script>
export default {
  name: "AppProgress",
  props: {
    type: {
      type: String,
      default: "circle",
      validator: (val) => ["line", "circle"].indexOf(val) > -1, // https://br.vuejs.org/v2/guide/components-props.html#Prop-Validation
    },
    progress: {
      type: Number,
      default: 0,
      validator: (val) => val >= 0 && val <= 100,
    },
    title: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    strokeWidth: {
      type: Number,
      default: 4,
    },
    width: {
      type: Number,
      default: 126,
    },
    loading: Boolean,
    /**
     * @TODO
     * Mensagens de aviso / Alteração de cores no componente.
     * Ex: Um usuario com 30% dos dados está com um status == 'warning'
     * status: {
     *   type: String
     */
  },

  computed: {
    relativeStrokeWidth() {
      return ((this.strokeWidth / this.width) * 100).toFixed(1);
    },
    trackPath() {
      const radius = parseInt(
        50 - parseFloat(this.relativeStrokeWidth) / 2,
        10
      );

      return `M 50 50 m 0 -${radius} a ${radius} ${radius} 0 1 1 0 ${
        radius * 2
      } a ${radius} ${radius} 0 1 1 0 -${radius * 2}`;
    },
    perimeter() {
      const radius = 50 - parseFloat(this.relativeStrokeWidth) / 2;
      return 2 * Math.PI * radius;
    },
    circlePathStyle() {
      const perimeter = this.perimeter;
      return {
        strokeDasharray: `${perimeter}px, ${perimeter}px`,
        strokeDashoffset: `${(1 - this.progress / 100) * perimeter}px`,
        transition: "stroke-dashoffset .6s ease 0s, stroke .6s ease",
      };
    },
    stroke() {
      const color = "#6abf57"; // assets/sass/__variables $green;
      /**
       * @TODO
       * Alteração das cores de acordo com o status
       */
      return color;
    },

    progressTextSize() {
      return this.type === "circle"
        ? this.width * 0.111111 + 10
        : (12 + this.strokeWidth) * 0.4;
    },
  },
};
</script>

<template>
  <div
    class="app__progress"
    :class="`app__progress--${type}`"
    role="progressbar"
    :aria-valuenow="progress"
    aria-valuemin="0"
    aria-valuemax="100"
  >
    <div
      class="app__progress-circle"
      :style="{
        height: `${width}px`,
        width: `${width}px`,
        marginBottom: label ? '5px' : 0,
      }"
      v-if="type === 'circle'"
    >
      <svg viewBox="0 0 100 100">
        <path
          class="app__progress-circle__track"
          :class="{ app__loading: loading }"
          :d="trackPath"
          stroke="#ebebeb"
          :stroke-width="relativeStrokeWidth"
          fill="none"
        ></path>
        <path
          class="app__progress-circle__path"
          :class="{ app__loading: loading }"
          :d="trackPath"
          stroke-linecap="round"
          :stroke="stroke"
          :stroke-width="relativeStrokeWidth"
          fill="none"
          :style="circlePathStyle"
        ></path>
      </svg>
      <span
        class="app__progress__text"
        :class="{ app__loading: loading }"
        :style="{ fontSize: `${progressTextSize}px` }"
      >
        {{ progress }}%
      </span>
    </div>

    <span
      class="app__progress__title"
      :class="{ app__loading: loading }"
      v-if="title"
    >
      {{ title }}
    </span>
    <span
      class="app__progress__label"
      :style="{ width: `${width}px` }"
      :class="{ app__loading: loading }"
      v-if="label"
    >
      {{ label }}
    </span>
  </div>
</template>
