<script>
import { mapActions, mapState, mapGetters } from "vuex";
import AppInputGroup from "@/features/general/InputGroup";
import AppIcon from "@/features/general/Icons";
import debounce from "lodash/debounce";
import { mask } from "vue-the-mask";
import useVuelidate from "@vuelidate/core";
import {
  required,
  maxLength,
  requiredIf,
  numeric,
} from "@vuelidate/validators";
import { isCnpjFormatValid } from "./validators/business";

export default {
  name: "AppCustomerBusinessEdit",
  directives: {
    mask,
  },
  components: {
    AppInputGroup,
    AppIcon,
  },
  props: {
    loading: Boolean,
    customer: {
      type: Object,
      default() {
        return {
          company: {
            name: "",
            cnpj: "",
            state_inscription: "",
          },
        };
      },
    },
  },

  setup() {
    /**
     * Objeto que mantém o estado das validações do vuelidate
     */
    return { v$: useVuelidate({ $autoDirty: true }) };
  },

  data() {
    return {
      isStateInscriptionChecked: true,
      cnpjAvailable: true,
      cnpjPending: false,
    };
  },

  /**
   * Seta o valor inicial default de v$.$invalid no state de customer
   */
  mounted() {
    this.$nextTick(() => {
      if (
        this.state_inscription.length > 0 &&
        this.state_inscription !== "isento"
      ) {
        this.isStateInscriptionChecked = false;
      }
      this.setIsBusinessDataInvalid({ value: this.v$.$invalid });
    });

    this.v$.$touch();
  },

  computed: {
    ...mapGetters(["langs"]),
    ...mapState({
      storeSettings: (state) => state.store_info.list.settings.store,
    }),
    ...mapState("Customer", {
      backendErrors: (state) => state.customer.backendErrors,
      vuelidateErrors: (state) => state.customer.vuelidateErrors,
    }),

    /* eslint-disable camelcase */
    company_name: {
      get() {
        return this.customer.company.name;
      },
      // eslint-disable-next-line
      set(name) {
        this.setData({
          list: {
            company: {
              ...this.customer.company,
              name,
            },
          },
        });
      },
    },

    cnpj: {
      get() {
        return this.customer.company.cnpj;
      },
      set(cnpj) {
        this.setData({
          list: {
            company: {
              ...this.customer.company,
              cnpj,
            },
          },
        });
      },
    },

    state_inscription: {
      get() {
        return this.customer.company.state_inscription;
      },
      set(state_inscription) {
        this.setData({
          list: {
            company: {
              ...this.customer.company,
              state_inscription,
            },
          },
        });
      },
    },

    /**
     * Validação do state_inscription
     * Caso seja isento de inscrição estadual, o campo não deve ser numérico
     * @returns {object}
     */
    stateInscriptionValidation() {
      if (this.state_inscription === "isento") {
        return {
          required,
        };
      }

      return {
        numeric,
        required,
        maxLength: maxLength(15),
      };
    },

    /**
     * Verifica se o CNPJ é obrigatório baseado nas configurações da loja
     * @return {bool}
     */
    isCnpjRequired() {
      const docsRequiredForPj = this.storeSettings.documents_requiredness_pj;
      const type = this.customer.type;
      const isRequired =
        docsRequiredForPj === "both" ||
        (type === "pj" && docsRequiredForPj === "cnpj");

      return isRequired;
    },
  },

  methods: {
    ...mapActions("Customer", [
      "setData",
      "setIsBusinessDataInvalid",
      "setVuelidateErrors",
    ]),

    /**
     * Retorna o state de acordo com as opções do validador
     * https://monterail.github.io/vuelidate/
     *
     * @param {object} v$.[model]
     * @param {string} validationMessage Mensagem de validação customizada
     * @param {string} isDisabled Flag para identificar quando o input deve ser desabilitado
     * @returns {boolean|string}
     */
    getValidatorState(
      { $dirty, $error, $invalid, $model, $path } = {},
      validationMessage,
      isDisabled = false,
      modelName = null
    ) {
      if ($path === "state_inscription" && $model === "isento") {
        $error = false;
      }

      if ($error !== this.vuelidateErrors[$path]) {
        this.setVuelidateErrors({ [$path]: $error });
      }

      if (validationMessage && validationMessage.length) {
        return "invalid";
      }

      if (isDisabled || this.isInitialByRequiredness(modelName, $model)) {
        return "initial";
      }

      if ($dirty) {
        return !$error;
      }

      const isNeutral = !$error && ($invalid || !$model);
      return isNeutral ? "initial" : !$invalid;
    },

    /**
     * Verifica se o estado do campo pode ser considerado [initial] quando
     * ele estiver vazio e não for obrigatório
     * @param {string} modelName Nome do campo
     * @param {string} modelValue Valor do campo
     * @return {bool}
     */
    isInitialByRequiredness(modelName, modelValue) {
      const isInitial =
        modelName === "cnpj" && modelValue.length === 0 && !this.isCnpjRequired;
      return isInitial;
    },

    /**
     * Verifica se o CNPJ está disponível para cadastro
     * @param {event} event evento disparado no input
     * @return {boolean}
     */
    validateCnpjDuplicity: debounce(function (event) {
      const currentCnpj = event.target.value;
      const isEmptyOptional = this.cnpj.length === 0 && this.isCnpjRequired;

      if (
        !currentCnpj ||
        !this.v$.cnpj.isCnpjFormatValid.$response ||
        isEmptyOptional
      ) {
        this.cnpjPending = false;
        this.cnpjAvailable = true;
        return true;
      }

      this.cnpjPending = true;

      const cnpjOnlyNumbers = currentCnpj.replace(/[^0-9]/g, "");

      this.$http
        .get(`/customer/${this.customer.id}/validate/cnpj/${cnpjOnlyNumbers}`)
        .then(() => {
          this.cnpjPending = false;
          this.cnpjAvailable = true;
        })

        .catch(() => {
          this.cnpjPending = false;
          this.cnpjAvailable = false;
        });
    }, 750),
  },

  validations() {
    return {
      company_name: {
        required,
        maxLength: maxLength(200),
      },
      cnpj: {
        required: requiredIf(() => this.isCnpjRequired),
        isCnpjFormatValid: isCnpjFormatValid(this.isCnpjRequired),
        isCnpjAvailable() {
          return this.cnpjAvailable;
        },
      },
      state_inscription: this.stateInscriptionValidation,
    };
  },

  watch: {
    /**
     * Monitora o estado inválido local dos inputs e envia para o state
     * geral de customer
     * @param {bool} value Se há campos inválidos(true) ou não(false)
     * @return {void}
     */
    "v$.$invalid"() {
      this.setIsBusinessDataInvalid({ value: this.v$.$invalid });
    },

    /**
     * Verifica quando o checkbox "Isento de Inscrição Estadual" está marcado
     * para limpar o input ou setar como "isento"
     * @param {bool} isChecked Se o checkbox está checado ou não
     * @return {bool}
     */
    isStateInscriptionChecked(isChecked) {
      if (isChecked) {
        this.state_inscription = "isento";
        return true;
      }

      if (!isChecked && this.state_inscription === "isento") {
        this.state_inscription = "";
        this.v$.state_inscription.$touch();
        return false;
      }
    },
  },
};
</script>
<template>
  <section class="app__customer-business-edit">
    <h3 class="app__customer__title app__customer__title--3">
      {{ this.langs.profile["company-information"] }}
    </h3>
    <div class="row">
      <div class="col-12 col-md-6">
        <app-input-group
          :label="this.langs.profile['company-name']"
          identifier="customer-company-name"
          :icon="{ prefix: 'far', name: 'building' }"
          :loading="loading"
          :value="company_name"
          :state="
            this.getValidatorState(v$.company_name, backendErrors.company_name)
          "
        >
          <template v-slot:input>
            <input
              type="text"
              :placeholder="this.langs.profile['company-name']"
              id="customer-company-name"
              class="app__input-group__input form-control"
              :class="{
                'app__input-group__input--is-invalid': v$.company_name.$error,
                'app__input-group__input--has-float-label': true,
                'app__input-group__input--is-empty': !company_name,
                app__loading: loading,
              }"
              v-model="company_name"
              @change="v$.company_name.$touch()"
            />
          </template>
          <template v-slot:feedbacks>
            <small
              v-if="v$.company_name.$error"
              class="app__input-group__feedbacks"
            >
              <span
                class="app__input-group__feedbacks__feedback"
                v-if="
                  !v$.company_name.required.$response &&
                  !backendErrors.company_name
                "
              >
                {{ this.langs.profile["inform-company-name"] }}
              </span>
            </small>
          </template>
          <template v-slot:feedbacks--backend>
            <small
              class="app__input-group__feedbacks app__input-group__feedbacks--backend"
            >
              <span
                v-if="backendErrors.company_name"
                class="app__input-group__feedbacks__feedback"
              >
                {{ backendErrors.company_name[0] }}
              </span>
            </small>
          </template>
        </app-input-group>
        <app-input-group
          label="CNPJ (12.345.678/0001-10)"
          identifier="customer-company-cnpj"
          :icon="{ prefix: 'far', name: 'id-card' }"
          :loading="loading"
          :value="cnpj"
          :state="
            cnpjPending
              ? 'pending'
              : this.getValidatorState(
                  v$.cnpj,
                  backendErrors.cnpj,
                  false,
                  'cnpj'
                )
          "
        >
          <template v-slot:input>
            <input
              type="tel"
              placeholder="CNPJ"
              id="customer-company-cnpj"
              class="app__input-group__input form-control"
              :class="{
                'app__input-group__input--is-invalid': v$.cnpj.$error,
                'app__input-group__input--has-float-label': true,
                'app__input-group__input--is-empty': !cnpj,
                app__loading: loading,
              }"
              v-model="cnpj"
              v-mask="'##.###.###/####-##'"
              @change="this.v$.cnpj.$touch()"
              @input="validateCnpjDuplicity"
            />
          </template>
          <template v-slot:feedbacks>
            <small v-if="v$.cnpj.$error" class="app__input-group__feedbacks">
              <span
                class="app__input-group__feedbacks__feedback"
                v-if="!v$.cnpj.required.$response && !backendErrors.cnpj"
              >
                {{ this.langs.profile["inform-cpnj"] }}
              </span>
              <span
                class="app__input-group__feedbacks__feedback"
                v-else-if="
                  v$.cnpj.required.$response &&
                  !v$.cnpj.isCnpjFormatValid.$response &&
                  !backendErrors.cnpj
                "
              >
                {{ this.langs.profile["valid-cnpj"] }}
              </span>
              <span
                class="app__input-group__feedbacks__feedback"
                v-else-if="
                  v$.cnpj.required.$response &&
                  v$.cnpj.isCnpjFormatValid.$response &&
                  !v$.cnpj.isCnpjAvailable.$response &&
                  !backendErrors.cnpj
                "
              >
                {{ this.langs.profile["cnpj-already-in-use"] }}
              </span>
            </small>
          </template>
          <template v-slot:feedbacks--backend>
            <small
              class="app__input-group__feedbacks app__input-group__feedbacks--backend"
            >
              <span
                v-if="backendErrors.cnpj"
                class="app__input-group__feedbacks__feedback"
              >
                {{ backendErrors.cnpj[0] }}
              </span>
            </small>
          </template>
        </app-input-group>
      </div>

      <div class="col-12 col-md-6">
        <div class="app__data-edit__input__wrapper">
          <input
            type="checkbox"
            id="customer-company-state-inscription-checkbox"
            class="app__data-edit__input__checkbox__input"
            v-model="isStateInscriptionChecked"
          />
          <label
            for="customer-company-state-inscription-checkbox"
            class="app__data-edit__input__checkbox"
            :class="{
              'app__data-edit__input__checkbox--is-checked':
                isStateInscriptionChecked,
            }"
          >
            <app-icon
              v-show="isStateInscriptionChecked"
              prefix="fa"
              name="check"
            >
            </app-icon>
          </label>
          <label
            for="customer-company-state-inscription-checkbox"
            class="app__data-edit__input__checkbox__label"
          >
            {{ this.langs.profile["free-inscription"] }}
          </label>
        </div>
        <app-input-group
          :label="this.langs.profile['state-inscription']"
          identifier="customer-company-state-inscription"
          :icon="{ prefix: 'far', name: 'file-alt' }"
          :loading="loading"
          :value="state_inscription"
          :state="
            this.getValidatorState(
              v$.state_inscription,
              backendErrors.state_inscription,
              isStateInscriptionChecked
            )
          "
        >
          <template v-slot:input>
            <input
              type="text"
              :placeholder="this.langs.profile['state-inscription']"
              id="customer-company-state-inscription"
              class="app__input-group__input form-control"
              maxLength="15"
              :disabled="isStateInscriptionChecked"
              :class="{
                'is-disabled': isStateInscriptionChecked,
                'app__input-group__input--has-float-label': true,
                'app__input-group__input--is-empty': !state_inscription,
                app__loading: loading,
              }"
              v-model="state_inscription"
              @change="v$.state_inscription.$touch()"
            />
          </template>
          <template v-slot:feedbacks>
            <small
              v-if="v$.state_inscription.$error && !isStateInscriptionChecked"
              class="app__input-group__feedbacks"
            >
              <span
                class="app__input-group__feedbacks__feedback"
                v-if="!v$.state_inscription.required.$response"
              >
                {{ this.langs.profile["inform-registration"] }}
              </span>
              <span
                class="app__input-group__feedbacks__feedback"
                v-else-if="v$.state_inscription.$errors"
              >
                {{ this.langs.profile["valid-registration"] }}
              </span>
            </small>
          </template>
          <template v-slot:feedbacks--backend>
            <small
              class="app__input-group__feedbacks app__input-group__feedbacks--backend"
            >
              <span
                v-if="backendErrors.state_inscription"
                class="app__input-group__feedbacks__feedback"
              >
                {{ backendErrors.state_inscription[0] }}
              </span>
            </small>
          </template>
        </app-input-group>
      </div>
    </div>
  </section>
</template>
