<script>
import { mapGetters, mapState, mapActions } from "vuex";
import useVuelidate from "@vuelidate/core";
import AppIcon from "@/features/general/Icons";
import AppSvg from "@/features/general/Svg";
import { SvgIcon } from "@/domains/Orders/components/order-total/icon-resolver/icon";
import icon from "@/mixins/icon";
import { required, sameAs } from "@vuelidate/validators";
import AppAlert from "@/features/bootstrap/Alert";
import AppInputGroup from "@/features/general/InputGroup";
import {
  isValidLength,
  containsLetter,
  containsNumber,
} from "./validators/password";

export default {
  name: "AppCustomerPasswordChange",
  mixins: [icon],
  components: {
    AppInputGroup,
    AppAlert,
    AppSvg,
    AppIcon,
  },

  setup() {
    /**
     * Objeto que mantém o estado das validações do vuelidate
     */
    return { v$: useVuelidate({ $autoDirty: true }) };
  },

  data() {
    return {
      alertDismissCountDown: 10,
      isSaving: false,
      password: "",
      confirmPassword: "",
      showPassword: false,
      showConfirmation: false,
      icons: [
        new SvgIcon("circle"),
        new SvgIcon("times"),
        new SvgIcon("check"),
      ],
    };
  },

  methods: {
    ...mapActions("Customer", [
      "setData",
      "setMessage",
      "clearMessages",
      "setBackendErrors",
      "clearBackendErrors",
    ]),

    /**
     * Altera a visibilidade da senha
     */
    toggleVisibility() {
      this.showPassword = !this.showPassword;
    },

    /**
     * Altera a visibilidade da confirmação de senha
     */
    toggleVisibilityConfirmation() {
      this.showConfirmation = !this.showConfirmation;
    },

    /**
     * Retorna o state de acordo com as opções do validador
     * https://monterail.github.io/vuelidate/
     *
     * @param {object} $dirty Se o input já sofreu interação do usuário
     * @param {object} $error Se o input é inválido e já sofreu interação do usuário, ou
     * seja, quando [$invalid] e [$dirty] são true.
     * @param {object} $invalid Se o input não "passa" em alguma validação
     * @param {object} $model Valor do input
     * @param {string} validationMessage Mensagem de validação customizada
     * @returns {boolean|string}
     */
    getValidatorState(
      { $dirty, $error, $invalid, $model } = {},
      validationMessage
    ) {
      const wasTouched = $dirty;
      const hasNoErrors = !$error;

      if (validationMessage && validationMessage.length) {
        return "invalid";
      }

      if (wasTouched) {
        return hasNoErrors;
      }

      const isAnyValidationInvalid = $invalid;
      const isEmpty = !$model;

      const isNeutral = hasNoErrors && (isAnyValidationInvalid || isEmpty);
      return isNeutral ? "initial" : !isAnyValidationInvalid;
    },

    onSubmit() {
      const route = `customer/${this.list.hash}/password-change`;
      const formData = {
        id: this.list.hash,
        password: this.password,
        confirmation: this.confirmPassword,
      };

      this.isSaving = true;
      this.setBackendErrors([]);

      this.$http
        .put(route, formData)
        .then((response) => {
          const { id } = response.data.message;
          this.$router.push({
            name: "password-change",
            params: {
              id,
            },
          });

          this.setMessage({
            variant: "success",
            text: `${this.langs.password_change.messages.success}`,
          });
        })
        .catch((error) => {
          const backErrors = {};
          Object.assign(backErrors, error);

          if (backErrors.data.error["extensions.attributes"] !== undefined) {
            const errorsObject = JSON.parse(
              error.data.error["extensions.attributes"][0]
            );
            backErrors.data.error["extensions.attributes"][0] = errorsObject;
          }

          const backendErrors = backErrors.data.error;
          this.setBackendErrors({ backendErrors });
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
  },

  computed: {
    ...mapGetters(["langs", "mainLoading"]),
    ...mapState("Customer", {
      list: (state) => state.customer.list,
      messages: (state) => state.customer.messages,
      backendErrors: (state) => state.customer.backendErrors,
    }),

    /**
     * Retorna o ícone
     * @return {array}
     */
    icon() {
      return this.hasDef(this.icons);
    },

    /**
     * Verifica se o tipo do icon é o tipo desejado
     * @return {bool}
     */
    hasIcon() {
      return this.icon.type === "svg";
    },

    /**
     * Verifica se a senha a senha atende os seguinte pré requisitos:
     * Mínimo de 8 caracteres
     * Pelo menos 1 número
     * Pelo menos 1 letra
     * O campo "confirmar nova senha" é igual a "nova senha"
     * @return {bool}
     */
    isInvalidPassword() {
      return (
        !this.v$.password.containsLetter.$response ||
        !this.v$.password.containsNumber.$response ||
        !this.v$.password.isValidLength.$response ||
        !this.v$.confirmPassword.sameAsPassword.$response
      );
    },
  },

  validations() {
    return {
      password: {
        required,
        isValidLength,
        containsLetter,
        containsNumber,
      },

      confirmPassword: {
        required,
        sameAsPassword: sameAs(this.password),
      },
    };
  },
};
</script>

<template>
  <section class="app__customer-password-change">
    <header class="app__customer-password-change__header">
      <h3
        class="app__customer-password-change__title--3"
        :class="{ app__loading: mainLoading }"
      >
        {{ this.langs.password_change.title }}
      </h3>
    </header>
    <app-alert :messages="messages" />
    <form
      class="app__customer app__customer-password-change__form"
      @submit.prevent="onSubmit"
    >
      <div class="app__customer-edit-password">
        <div class="row">
          <div class="col-12 col-md-9 position-relative">
            <app-input-group
              :label="`${this.langs.password_change['new-password']}`"
              :class="{ app__loading: mainLoading }"
              class="newPassword"
              :icon="{ prefix: 'fas', name: 'lock' }"
              :value="password"
              identifier="new-password"
              :loading="mainLoading"
              :state="this.getValidatorState(v$.password)"
            >
              <template v-slot:input>
                <input
                  :type="showPassword ? 'text' : 'password'"
                  :placeholder="`${this.langs.password_change['new-password']}`"
                  id="new-password"
                  class="app__input-group__input form-control"
                  :class="{
                    'app__input-group__input--has-float-label': true,
                    'app__input-group__input--is-empty': !password,
                    app__loading: mainLoading,
                  }"
                  v-model.trim="password"
                  @input="v$.password.$touch()"
                />
              </template>
              <template v-slot:feedbacks>
                <small
                  class="app__input-group__feedbacks app__customer-password-change__errors"
                >
                  <span
                    v-if="backendErrors.password"
                    class="app__input-group__feedbacks__feedback app__customer-password-change__password_error"
                  >
                    {{ backendErrors.password[0] }}
                  </span>
                </small>
              </template>
            </app-input-group>

            <button
              class="app__customer-password-change__toggle-visibility"
              :class="{ app__loading: mainLoading }"
              @click.prevent="toggleVisibility()"
            >
              <app-icon
                class="app__icon"
                prefix="far"
                :name="showPassword ? 'eye' : 'eye-slash'"
              >
              </app-icon>
            </button>
          </div>

          <div class="col-12 col-md-9 position-relative">
            <app-input-group
              :label="`${this.langs.password_change['confirm-password']}`"
              class="passwordConfirmation"
              :class="{ app__loading: mainLoading }"
              :icon="{ prefix: 'fas', name: 'lock' }"
              :value="confirmPassword"
              identifier="confirm-password"
              :loading="mainLoading"
              :state="this.getValidatorState(v$.confirmPassword)"
            >
              <template v-slot:input>
                <input
                  :type="showConfirmation ? 'text' : 'password'"
                  :placeholder="`${this.langs.password_change['confirm-password']}`"
                  id="confirm-password"
                  class="app__input-group__input form-control"
                  :class="{
                    'app__input-group__input--has-float-label': true,
                    'app__input-group__input--is-empty': !confirmPassword,
                    app__loading: mainLoading,
                  }"
                  v-model.trim="confirmPassword"
                  @input="v$.confirmPassword.$touch()"
                />
              </template>
              <template v-slot:feedbacks>
                <small
                  class="app__input-group__feedbacks app__customer-password-change__errors"
                >
                  <span
                    v-if="backendErrors.confirmation"
                    class="app__input-group__feedbacks__feedback"
                  >
                    {{ backendErrors.confirmation[0] }}
                  </span>
                </small>
                <small
                  class="app__input-group__feedbacks app__customer-password-change__errors"
                >
                  <span
                    v-if="!v$.confirmPassword.sameAsPassword.$response"
                    class="app__input-group__feedbacks__feedback app__customer-password-change__confirmation_error"
                  >
                    {{ this.langs.password_change.errors.equal }}
                  </span>
                </small>
              </template>
            </app-input-group>

            <button
              class="app__customer-password-change__toggle-visibility"
              :class="{ app__loading: mainLoading }"
              @click.prevent="toggleVisibilityConfirmation()"
            >
              <app-icon
                class="app__icon"
                prefix="far"
                :name="showConfirmation ? 'eye' : 'eye-slash'"
              >
              </app-icon>
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="app__customer-password-change__validation-rules">
              <span
                class="app__customer-password-change__validation-rules__contains"
              >
                {{ this.langs.password_change["must-contain"] }}
              </span>
              <span
                class="app__customer-password-change__validation-rules__list"
              >
                <dl>
                  <dt
                    class="app__customer-password-change__validation-rules__list__item"
                    :class="{ app__loading: mainLoading }"
                  >
                    <app-svg
                      :name="
                        !v$.password.isValidLength.$response ? 'times' : 'check'
                      "
                      class="app__icon--rules"
                      :class="{ app__loading: mainLoading }"
                    >
                    </app-svg>
                    {{ this.langs.password_change["min-characters"] }}
                  </dt>
                  <dt
                    class="app__customer-password-change__validation-rules__list__item"
                    :class="{ app__loading: mainLoading }"
                  >
                    <app-svg
                      :name="
                        !v$.password.containsNumber.$response
                          ? 'times'
                          : 'check'
                      "
                      class="app__icon--rules"
                      :class="{ app__loading: mainLoading }"
                    >
                    </app-svg>
                    {{ this.langs.password_change["min-number"] }}
                  </dt>
                  <dt
                    class="app__customer-password-change__validation-rules__list__item"
                    :class="{ app__loading: mainLoading }"
                  >
                    <app-svg
                      :name="
                        !v$.password.containsLetter.$response
                          ? 'times'
                          : 'check'
                      "
                      class="app__icon--rules"
                      :class="{ app__loading: mainLoading }"
                    >
                    </app-svg>
                    {{ this.langs.password_change["min-letter"] }}
                  </dt>
                </dl>
              </span>
            </div>
          </div>
        </div>
        <div class="text-center app__customer-password-change__actions">
          <button
            class="app__button app__button--primary app__button--no-margin"
            :class="{
              app__loading: mainLoading,
            }"
            :disabled="isInvalidPassword || isSaving"
          >
            {{ this.langs.password_change["save-password"] }}
            <app-icon
              class="app__input-group__icon-sync-alt app__icon--infinite-rotate"
              prefix="fa"
              name="sync-alt"
              v-show="isSaving"
            >
            </app-icon>
          </button>
        </div>
      </div>
    </form>
  </section>
</template>
