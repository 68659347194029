<script>
import { mapActions, mapGetters, mapState } from "vuex";
import AppIcon from "@/features/general/Icons";

export default {
  name: "AppCustomerProfile",
  components: {
    AppIcon,
  },

  created() {
    this.fetch();
  },

  props: {
    loading: Boolean,
  },

  computed: {
    ...mapGetters(["storeInfo", "langs"]),
    ...mapState("Customer", {
      selectedProfile: (state) => state.customer.selectedProfile,
      profiles: (state) => state.customer.profiles,
    }),
    ...mapState({
      store_info: (state) => state.store_info.list,
    }),

    /**
     * Define qual será a rota que o link irá direcionar
     * @returns {string}
     */
    routePath() {
      const purchaseProfileRoute = "/profile/purchase-profile";

      return this.$route.fullPath === purchaseProfileRoute
        ? "/profile"
        : purchaseProfileRoute;
    },

    /**
     * Define qual será o texto do link
     * @returns {string}
     */
    routePathText() {
      return this.$route.fullPath === "/profile/purchase-profile"
        ? this.langs.profile["back"]
        : this.langs.profile["change-profile"];
    },
  },

  methods: {
    ...mapActions("Customer", ["setProfiles", "setSelectedProfile"]),

    async fetch() {
      try {
        const { data } = await this.$http.get("profiles");
        this.setProfiles(data.customerProfiles);
        this.setSelectedProfile(data.profiles.name);
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * Verifica se a troca de perfil está habilitada
     * @returns {boolean}
     */
    changeProfile() {
      return this.storeInfo.settings.store.change_profile === "1";
    },

    /**
     * Retorna a url para troca de perfil
     * @returns {boolean}
     */
    changeProfileUrl() {
      return `${this.storeInfo.uri.https}/mvc/store/central/perfil/perfil_clientes/seleciona_perfil/loja:${this.storeInfo.id}`;
    },
  },
};
</script>

<template>
  <div class="app__profile-info" :class="{ app__loading: loading }">
    <figure class="app__icon">
      <app-icon name="briefcase" prefix="fa"></app-icon>
    </figure>
    <div class="app__profile-info__text">
      <span>
        {{ this.langs.profile["purchasing_profile"] }}:
        <strong
          :class="{ app__loading: loading }"
          class="app__profile-info__name"
        >
          {{ selectedProfile }}
        </strong>
      </span>
      <router-link
        tag="a"
        v-if="changeProfile()"
        :to="{
          path: this.routePath,
        }"
        :title="this.routePathText"
        class="app__profile-info__link app__link"
      >
        {{ this.routePathText }}
      </router-link>
    </div>
  </div>
</template>
