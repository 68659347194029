<script>
import { mapState, mapActions, mapGetters } from "vuex";
import AppProfileInfo from "../profile-info.vue";
import AppAlert from "@/features/bootstrap/Alert";
import AppIcon from "@/features/general/Icons";

export default {
  name: "AppPurchaseProfile",

  data() {
    return {
      isSaving: false,
      isDisabledButton: true,
      selectedPurchaseProfile: String,
      selectedPurchaseProfileName: String,
    };
  },

  components: {
    AppProfileInfo,
    AppAlert,
    AppIcon,
  },

  mounted() {
    if (this.storeInfo.settings.store.change_profile !== "1") {
      this.$router.push({ name: "profile.main" });
    }
  },

  methods: {
    ...mapActions("Customer", [
      "setMessage",
      "clearMessages",
      "setSelectedProfile",
    ]),

    /**
     * Verifica se o item da lista de perfis é igual ao perfil do usuário.
     *
     * @param {string} item nome do perfil
     * @return {boolean}
     */
    checkedOption(item) {
      return this.selectedProfile === item;
    },

    /**
     * Altera o valor do perfil selecionado e valida o estado do botão.
     * Caso a opção selecionada seja a mesma do perfil do usuário, o botão fica desativado.
     *
     * @param {string} value nome do perfil
     * @return {undefined}
     */
    setSelectedPurchaseProfileName(value) {
      this.selectedPurchaseProfileName = value;

      this.isDisabledButton = this.checkedOption(
        this.selectedPurchaseProfileName
      );
    },

    /**
     * Faz a requisição para alterar o perfil e envia um alert de sucesso ou erro
     * @return {undefined}
     */
    onSubmit() {
      this.clearMessages();
      this.isSaving = true;
      const path = `profiles/update/${this.selectedPurchaseProfile}`;

      this.$http
        .put(path)
        .then(() => {
          this.setMessage({
            variant: "success",
            text: this.langs.profile["edit_purchasing_profile_success"],
          });
          this.setSelectedProfile(this.selectedPurchaseProfileName);
          this.isDisabledButton = true;
          this.isSaving = false;
        })
        .catch(() => {
          this.setMessage({
            variant: "danger",
            text: this.langs.profile["edit_purchasing_profile_failure"],
          });
          this.isSaving = false;
        });
    },
  },

  computed: {
    ...mapGetters(["mainLoading", "langs", "storeInfo"]),
    ...mapState("Customer", {
      list: (state) => state.customer.list,
      messages: (state) => state.customer.messages,
      selectedProfile: (state) => state.customer.selectedProfile,
      profiles: (state) => state.customer.profiles,
    }),
    ...mapState({
      store_info: (state) => state.store_info.list,
    }),
  },
};
</script>

<template>
  <section class="app__purchase-profile">
    <header>
      <h3 class="__title--3" :class="{ app__loading: mainLoading }">
        {{ this.langs.sidebar_menu["my-information"] }}
      </h3>
    </header>
    <form class="app__customer" @submit.prevent="onSubmit">
      <app-profile-info :loading="mainLoading" />
      <app-alert :messages="messages" />
      <p
        class="app__purchase-profile__description"
        :class="{ app__loading: mainLoading }"
      >
        {{ this.langs.profile["purchasing-profile-to-use"] }}
      </p>

      <div v-for="item in profiles" v-bind:key="item.id">
        <input
          class="form-check-input"
          type="radio"
          name="flexRadioDefault"
          :id="`flexRadioDefault${item.id}`"
          :value="item.hash"
          :checked="checkedOption(item.name)"
          v-model="selectedPurchaseProfile"
          @click="setSelectedPurchaseProfileName(item.name)"
        />
        <label
          class="app__purchase-profile__input-container form-check"
          :class="{
            app__loading: mainLoading,
          }"
          :for="`flexRadioDefault${item.id}`"
        >
          <span class="checkmark" :class="{ app__loading: mainLoading }"></span>
          <div class="app__purchase-profile__input-label form-check-label">
            <span
              class="app__purchase-profile__input-label__span"
              :class="{ app__loading: mainLoading }"
              >{{ item.name }}</span
            >
          </div>
        </label>
      </div>

      <div class="row">
        <div class="col-12 app__customer__actions">
          <button
            class="app__button app__button--primary app__button--no-margin"
            :class="{
              app__loading: mainLoading,
              'app__button--disabled': isSaving,
              app__isSaving: isSaving,
            }"
            type="submit"
            :disabled="isDisabledButton"
          >
            {{ this.langs.profile["save_changes"] }}
            <app-icon
              class="app__input-group__icon-sync-alt app__icon--infinite-rotate"
              prefix="fa"
              name="sync-alt"
              v-show="isSaving"
            >
            </app-icon>
          </button>
        </div>
      </div>
    </form>
  </section>
</template>
